import React, { useEffect, useState } from 'react';
import HeaderSearch from 'components/home-page/header-search';
import Link from 'components/link';
import Layout from 'components/layout';
import useQueryParams from 'hooks/use-query-params';
import AnimatedCarousel from '../animated-carousel';
import { useModalContext } from 'contexts/modal';
import Button from 'components/control/button';
import { buildClassName } from 'utils/build-class-name';
import { trackEvent } from 'utils/google-tag-manager';
import styles from './style.module.scss';
import DEFAULT_LISTING_PARAMS from 'contexts/preferences/listing-params/defaults';
import data from 'components/home-page/internal-links/data.json';
import NotificationBanner from 'components/notification-banner';
import { useUserContext, usePreferencesContext } from 'contexts';
import { useThemeContext } from 'contexts/theme';
import { CountryCodeList } from 'types/countries';
import { testIds } from 'constants/test-constants';
import { dasherize } from '@zoocasa/node-kit/strings';
import { findLocationByPlaceId } from 'utils/google-maps/geoLocator';
import { checkLocationHasAreaPageWithId, getLocationSlugFromId } from 'data/search-predictions';
import { GTM_CLICK_HOMEPAGE_SOLD_BUTTON, GTM_CLICK_HOMEPAGE_MARKET_INSIGHTS_BUTTON,
  GTM_CLICK_HOMEPAGE_FEATURED_BUTTON, GTM_CLICK_HOMEPAGE_BUYERS_CARD_CLICK, GTM_CLICK_HOMEPAGE_BUYERS_BUTTON_CLICK,
  GTM_CLICK_HOMEPAGE_SELLERS_CARD_CLICK, GTM_CLICK_HOMEPAGE_SELLERS_BUTTON_CLICK } from 'constants/events';
import { ModalOpeners } from 'types/modal';
import replaceFrenchAccents from 'utils/replace-french-accents';
import { ThemeNames } from 'types/themes';
import LoadWhenVisible from 'components/load-when-visible';
import LoadingSkeleton from 'components/loading-skeleton';
import dynamic from 'next/dynamic';
import ZoocasaForSaleSection from './themed-for-sale-section/zoocasa';
import ExpForSaleSection from './themed-for-sale-section/exp';
import VideoReveal from 'components/video-reveal';

import type { INamedContents } from 'pages';
import type { ListingCardData } from 'components/listing-card';

export interface Props {
  isCrawler: boolean;
  featuredHomesMlsNums: INamedContents['featuredHomesMlsNums'];
  isNotificationBannerHidden: boolean;
  availableHomeListings: ListingCardData[];
  availableHomeListingsMeta: number;
  availableHomeListingsLocation: string;
  isExpandedArea?: boolean;
}

export default function HomePage({ isCrawler, featuredHomesMlsNums, isNotificationBannerHidden, availableHomeListings, availableHomeListingsMeta, availableHomeListingsLocation, isExpandedArea }: Props) {
  const { userLocation, siteLocation } = useUserContext();
  const { lastSearchLocation } = usePreferencesContext();
  const { openModal } = useModalContext();
  const { themeName, theme } = useThemeContext();
  const isExpTheme = themeName !== ThemeNames.ZOOCASA;

  const [homesForSaleUrl, setHomesForSaleUrl] = useState(`/${siteLocation?.toLowerCase()}-real-estate`);
  const [soldPricesUrl, setSoldPricesUrl] = useState(`/${siteLocation?.toLowerCase()}-real-estate/sold`);
  const [mapViewUrl, setMapViewUrl] = useState(`/search?latitude=${DEFAULT_LISTING_PARAMS.filter.latitude}&longitude=${DEFAULT_LISTING_PARAMS.filter.longitude}`);
  const [isSoldHomesVisible, setIsSoldHomesVisible] = useState(false);
  const [isInternalLinksVisible, setIsInternalLinksVisible] = useState(false);
  const [isKeywordsListVisible, setIsKeywordsListVisible] = useState(false);
  const [isExpAwardsVisible, setIsExpAwardsVisible] = useState(false);
  const [isExpJoinVisible, setIsExpJoinVisible] = useState(false);
  const [isExpContentCardsVisible, setIsExpContentCardsVisible] = useState(false);
  const [isFeaturedHomesVisible, setIsFeaturedHomesVisible] = useState(false);
  const [isBlogVisible, setIsBlogVisible] = useState(false);
  const [isContentBlockVisible, setIsContentBlockVisible] = useState(false);
  const [HomeListingsModule, setHomeListingsModule] = useState<any>();
  const [Blog, setBlog] = useState<any>();
  const [ExpAwardCarousel, setExpAwardCarousel] = useState<any>();
  const [ExpJoinCarousel, setExpJoinCarousel] = useState<any>();
  const [ExpContentCards, setExpContentCards] = useState<any>();
  const [KeywordsList, setKeywordsList] = useState<any>();
  const [InternalLinks, setInternalLinks] = useState<any>();
  const [InternalLinksExp, setInternalLinksExp] = useState<any>();
  const [ContentBlock, setContentBlock] = useState<any>();
  const isCanadianSite = siteLocation === CountryCodeList.CANADA;

  const generateUrls = async () => {
    if (lastSearchLocation) {
      let lastSearchLatitude = lastSearchLocation?.data?.position?.latitude || lastSearchLocation?.data?.position?.coordinates[1];
      let lastSearchLongitude = lastSearchLocation?.data?.position?.longitude || lastSearchLocation?.data?.position?.coordinates[0];
      if (!lastSearchLongitude || !lastSearchLongitude) {
        const geoLocation = await findLocationByPlaceId(lastSearchLocation?.locationId || lastSearchLocation?.id);
        lastSearchLatitude = geoLocation?.latitude;
        lastSearchLongitude = geoLocation?.longitude;
      }
      const hasAreaPage = checkLocationHasAreaPageWithId(lastSearchLocation.id);
      if (hasAreaPage) {
        let location = getLocationSlugFromId(lastSearchLocation.id);
        let subLocation = '';
        if (lastSearchLocation.label === 'neighbourhood') {
          const locationArr = lastSearchLocation.description.split(', ');
          subLocation = '/' + dasherize(locationArr[0]);
          location = dasherize((locationArr[1] + ' ' + locationArr[2]).toLowerCase());
        }
        const updatedHomeUrl = `/${location}-real-estate${subLocation}`;
        setHomesForSaleUrl(updatedHomeUrl);
        setSoldPricesUrl(`${updatedHomeUrl}/sold`);
      } else {
        const updatedHomeUrl = `/search?latitude=${lastSearchLatitude}&longitude=${lastSearchLongitude}`;
        setHomesForSaleUrl(updatedHomeUrl);
        setSoldPricesUrl(`${updatedHomeUrl}&status=not-available-sold`);
      }
      setMapViewUrl(`/search?latitude=${lastSearchLatitude}&longitude=${lastSearchLongitude}`);
    } else if (userLocation) {
      const updatedHomeUrl = `/${userLocation.slug}-real-estate`;
      setHomesForSaleUrl(updatedHomeUrl);
      setSoldPricesUrl(`${updatedHomeUrl}/sold`);
      setMapViewUrl(`/search?latitude=${userLocation?.latitude}&longitude=${userLocation?.longitude}`);
    }
  };

  const generateInternalLinks = () => {
    if (themeName === ThemeNames.EXP_REALTY_CA) {
      return data['exp-ca-links'];
    } else if (siteLocation === CountryCodeList.UNITED_STATES) {
      return data['usa-links'];
    } else if (siteLocation === CountryCodeList.CANADA) {
      return data['canadian-links'];
    }
  };

  useQueryParams(params => openModal('reset-password', params), ModalOpeners.RESET_PASSWORD_TOKEN, ModalOpeners.EMAIL);
  useQueryParams(params => openModal('confirm-account', params), ModalOpeners.CONFIRMATION_TOKEN, ModalOpeners.USER_AGENT);
  useQueryParams(params => openModal('login-registration', params), ModalOpeners.SIGNIN_EMAIL);
  useQueryParams(params => openModal('login-registration', params), ModalOpeners.WARNING_MESSAGE);
  useQueryParams(params => openModal('notification', { message: params.notification as string }), ModalOpeners.NOTIFICATION);

  useEffect(() => {
    generateUrls();
    trackEvent('UiHomePView');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSoldHomesVisible && !HomeListingsModule) {
      setHomeListingsModule(dynamic(import('components/home-page/home-modules')));
    }
    if (isFeaturedHomesVisible && !HomeListingsModule) {
      setHomeListingsModule(dynamic(import('components/home-page/home-modules')));
    }
    if (isInternalLinksVisible && !InternalLinks) {
      setInternalLinks(dynamic(import('components/home-page/internal-links')));
      setInternalLinksExp(dynamic(import('components/home-page/internal-links-exp')));
    }
    if (isKeywordsListVisible && !KeywordsList) {
      setKeywordsList(dynamic(import('components/home-page/keywords-list')));
    }
    if (isBlogVisible && !Blog) {
      setBlog(dynamic(import('components/home-page/blog')));
    }
    if (isExpAwardsVisible && !ExpAwardCarousel) {
      setExpAwardCarousel(dynamic(import('components/home-page/exp-components/award-carousel')));
    }
    if (isExpJoinVisible && !ExpJoinCarousel) {
      setExpJoinCarousel(dynamic(import('components/home-page/exp-components/join-exp')));
    }
    if (isExpContentCardsVisible && !ExpContentCards) {
      setExpContentCards(dynamic(import('components/content-cards')));
    }
    if (isContentBlockVisible && !ContentBlock) {
      setContentBlock(dynamic(import('components/content-block')));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSoldHomesVisible, isFeaturedHomesVisible, isInternalLinksVisible, isKeywordsListVisible, isBlogVisible, isExpAwardsVisible, isExpContentCardsVisible, isContentBlockVisible]);

  return (
    <Layout>
      <NotificationBanner label="New Construction Homes are here!" link="/newhomes" isHidden={isNotificationBannerHidden} />
      {isExpTheme ? <AnimatedCarousel /> : <HeaderSearch />}
      {isExpTheme && <div className={styles['exp-homes-sale-divider']} id="skipTo"/>}
      {isExpTheme && <VideoReveal />}
      <section className={styles.component}>
        <div className={buildClassName(styles['home-modules'], isExpTheme ? styles['exp-home'] : '')}>
          {!isExpTheme && <ZoocasaForSaleSection isCrawler={isCrawler} availableHomeListings={availableHomeListings} availableHomeListingsMeta={availableHomeListingsMeta} availableHomeListingsLocation={availableHomeListingsLocation} isExpandedArea={isExpandedArea} homesForSaleUrl={homesForSaleUrl} mapViewUrl={mapViewUrl} />}
          {isExpTheme && <ExpForSaleSection isCrawler={isCrawler} availableHomeListings={availableHomeListings} availableHomeListingsMeta={availableHomeListingsMeta} availableHomeListingsLocation={availableHomeListingsLocation} isExpandedArea={isExpandedArea} homesForSaleUrl={homesForSaleUrl} mapViewUrl={mapViewUrl} />}
          {!isExpTheme && <section className={buildClassName(styles['home-sections'], styles.sold, !isCanadianSite && !isExpTheme && styles.full)}>
            <LoadWhenVisible onValueChange={() => setIsSoldHomesVisible(true)}>
              {!isSoldHomesVisible && <LoadingSkeleton />}
              {isSoldHomesVisible && HomeListingsModule &&
                <div className={styles['home-block']} data-testid={testIds.soldHomeListings}>
                  <div className={styles.row}>
                    <Link className={buildClassName(isExpTheme && styles['exp-headings'])}
                      href={replaceFrenchAccents(soldPricesUrl)} testId={testIds.soldPricesButtonLink} onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_SOLD_BUTTON)}>
                      <h2>Sold Prices</h2>
                    </Link>
                    <Link href={replaceFrenchAccents(soldPricesUrl)}>
                      <Button label="More Data" theme="secondary" expHomePadding={isExpTheme} onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_SOLD_BUTTON)} testId={testIds.moreDataButton} />
                    </Link>
                    <p className={styles.label}>Search Sold Data from 2003 - {new Date().getFullYear()}</p>
                  </div>
                  <HomeListingsModule type="sold" userLocation={userLocation} redirectUrl={replaceFrenchAccents(soldPricesUrl)} isCrawler={isCrawler}/>
                </div>
              }
            </LoadWhenVisible>
          </section>}
          {(isCanadianSite && !isExpTheme) &&
            <section className={buildClassName(styles['home-sections'], styles['market-insights'])}>
              <LoadWhenVisible onValueChange={() => setIsBlogVisible(true)}>
                {!isBlogVisible && <LoadingSkeleton />}
                {isBlogVisible && Blog &&
                  <div className={styles['home-block']} data-testid={testIds.marketInsightsWidget}>
                    <div className={styles.row}>
                      <a href="https://www.zoocasa.com/blog/" data-testid={testIds.marketInsightsLink}><h2 onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_MARKET_INSIGHTS_BUTTON)}>Market Insights</h2></a>
                      <a href="https://www.zoocasa.com/blog/" data-testid={testIds.marketInsightsMoreNews}><Button label="More News" theme="secondary" onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_MARKET_INSIGHTS_BUTTON)} /></a>
                      <p className={styles.label}>Read the latest real estate news and reports</p>
                    </div>
                    <Blog />
                  </div>
                }
              </LoadWhenVisible>
            </section>
          }
          {isExpTheme &&
            <>
              <section className={styles['home-sections']}>
                <LoadWhenVisible onValueChange={() => setIsExpAwardsVisible(true)}>
                  {isExpAwardsVisible && ExpAwardCarousel && <ExpAwardCarousel />}
                </LoadWhenVisible>
              </section>
              <section className={styles['home-sections']}>
                <LoadWhenVisible onValueChange={() => setIsExpJoinVisible(true)}>
                  {isExpJoinVisible && ExpJoinCarousel && <ExpJoinCarousel />}
                </LoadWhenVisible>
              </section>
              <div className={styles['exp-sold-price-divider']}/>
              <section className={styles['home-sections']}>
                <LoadWhenVisible onValueChange={() => setIsExpContentCardsVisible(true)}>
                  {isExpContentCardsVisible && ExpContentCards && <ExpContentCards />}
                </LoadWhenVisible>
              </section>
            </>
          }
          {isCanadianSite && !isExpTheme &&
            <section className={buildClassName(styles['home-sections'], styles['featured-homes'])}>
              <LoadWhenVisible onValueChange={() => setIsFeaturedHomesVisible(true)}>
                {!isFeaturedHomesVisible && <LoadingSkeleton/>}
                {isFeaturedHomesVisible && HomeListingsModule &&
                  <div className={styles['home-block']} data-testid={testIds.featuredHomesListings}>
                    <div className={styles.row}>
                      <Link href="/ca-real-estate/houses" onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_FEATURED_BUTTON)}>
                        <h2 data-testid={testIds.featuredHomesLink}>Featured Homes</h2>
                      </Link>
                      <Link href="/ca-real-estate/houses"><Button label="More Listings" theme="secondary" testId={testIds.featuredHomesMoreListings} onClick={() => trackEvent(GTM_CLICK_HOMEPAGE_FEATURED_BUTTON)}/></Link>
                    </div>
                    <HomeListingsModule type="featured" featuredHomesMlsNums={featuredHomesMlsNums} userLocation={userLocation} isCrawler={isCrawler}/>
                  </div>
                }
              </LoadWhenVisible>
            </section>
          }
          {!isExpTheme &&
            <>
              <section className={buildClassName(styles['home-sections'], styles['content-block'], styles['buy-guide'], (!isCanadianSite || isExpTheme) && styles.half)}>
                <LoadWhenVisible onValueChange={() => setIsContentBlockVisible(true)}>
                  {!isContentBlockVisible && <LoadingSkeleton/>}
                  {isContentBlockVisible && ContentBlock &&
                    <div className={styles['home-block']}>
                      <ContentBlock themeName={themeName} content={theme.contentBlockBuy} cardEvent={GTM_CLICK_HOMEPAGE_BUYERS_CARD_CLICK} buttonEvent={GTM_CLICK_HOMEPAGE_BUYERS_BUTTON_CLICK} />
                    </div>
                  }
                </LoadWhenVisible>
              </section>
              <section className={buildClassName(styles['home-sections'], styles['content-block'], styles['buy-guide'], (!isCanadianSite || isExpTheme) && styles.half)}>
                <LoadWhenVisible onValueChange={() => setIsContentBlockVisible(true)}>
                  {!isContentBlockVisible && <LoadingSkeleton />}
                  {isContentBlockVisible && ContentBlock &&
                      <div className={styles['home-block']}>
                        <ContentBlock themeName={themeName} content={theme.contentBlockSell} cardEvent={GTM_CLICK_HOMEPAGE_SELLERS_CARD_CLICK} buttonEvent={GTM_CLICK_HOMEPAGE_SELLERS_BUTTON_CLICK} />
                      </div>
                  }
                </LoadWhenVisible>
              </section>
            </>
          }
          {isExpTheme && <div className={styles['exp-sold-price-divider']} />}
        </div>
        {isCanadianSite &&
          <section className={buildClassName(styles['home-sections'], isExpTheme ? styles['find-by-keyword-exp'] : styles['find-by-keyword'])} data-testid={testIds.homeKeywords}>
            <div className={styles.container}>
              <LoadWhenVisible onValueChange={() => setIsKeywordsListVisible(true)}>
                {!isKeywordsListVisible && <LoadingSkeleton />}
                {isKeywordsListVisible && KeywordsList &&
                  <div className={buildClassName(styles['home-block'], isExpTheme ? styles['exp-remove-btm-padding'] : '')}>
                    <div className={buildClassName(styles.row, styles['find-by-keyword-heading'])} data-testid={testIds.findByKeyword}>
                      {isExpTheme ? <h2>Search by <b>Keywords</b></h2> : <h2>Search by Keywords</h2>}
                    </div>
                    <div className={buildClassName(isExpTheme ? styles['keywords-list'] : '')}>
                      <KeywordsList />
                    </div>
                  </div>
                }
              </LoadWhenVisible>
            </div>
          </section>
        }
      </section>
      <LoadWhenVisible onValueChange={() => setIsInternalLinksVisible(true)}>
        {!isInternalLinksVisible && <LoadingSkeleton />}
        {isInternalLinksVisible && InternalLinks && isExpTheme && <InternalLinksExp isCrawler={ isCrawler } data={generateInternalLinks()} />}
        {isInternalLinksVisible && InternalLinks && !isExpTheme && <InternalLinks isCrawler={ isCrawler } data={generateInternalLinks()} isHomepage={ true } />}
      </LoadWhenVisible>
    </Layout>
  );
}
