import React, { useContext } from 'react';
import { buildClassName } from 'utils/build-class-name';
import { ModalContext, IModalContext } from 'contexts/modal';
import { UserContext, IUserContext } from 'contexts/user';
import formatCurrency from 'utils/format-currency';
import styles from './style.module.scss';
import { listingPageModalIds } from 'constants/test-constants';
import { TryScrambleText } from 'utils/text-scrambler';
import InfoIcon from 'components/icon/info-icon';
import { ProvinceOrStateCode, isUsStateCode, isCanadianProvinceCode } from 'utils/province_or_state';
import { useTranslation } from 'react-i18next';
import { SiteNameSpace } from 'constants/locale';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

export interface ListingPriceData {
  statusLabel: string;
  isTreb: boolean;
  isCrea: boolean;
  isItsoVow: boolean;
  isPillar9Vow: boolean;
  isRebgvVow: boolean;
  providerId: number | null;
  soldPrice: number | null;
  price: number | null;
  isSoldWithoutSoldPrice: boolean;
  isPrivate: boolean;
  agreementType: string | undefined;
  isSignInAndTermsOfUseRequired: boolean;
  province: string | null;
}
interface Props {
  listing: ListingPriceData;
  isVisible: boolean;
  isMinimal?: boolean;
  isSmallCard?: boolean;
  testId?: string;
  shouldScramble: boolean;
  showsInfoIcon?: boolean;
  isExpTheme?: boolean;
}

export default function ListingPrice({ listing, isVisible, isMinimal = false, isSmallCard = false, testId, shouldScramble, showsInfoIcon, isExpTheme }: Props) {
  const { statusLabel, isTreb, soldPrice, isSoldWithoutSoldPrice, price, isPrivate: listingIsPrivate, isCrea, isSignInAndTermsOfUseRequired, providerId } = listing;
  const { isAuthenticated } = useContext(UserContext) as IUserContext;
  const isPrivate = !isAuthenticated && listingIsPrivate;
  const isInactive = statusLabel === 'Inactive';
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { themeName } = useThemeContext();
  const isExpThemeStyle = isExpTheme || (themeName && themeName !== ThemeNames.ZOOCASA);
  const { t } = useTranslation(SiteNameSpace.Modal);
  const hiddenPriceLabel = isExpThemeStyle ? '$--,---,---' : '$X';

  const openSignInModal = () => {
    if (isPrivate || (isSignInAndTermsOfUseRequired && !isVisible && !isAuthenticated)) {
      openModal('login-registration', { warningMessage: t('login-registration.form.signinRequiredWarning') });
    } else if (!isVisible) {
      openModal('terms-of-use', { providerId });
    }
  };

  const renderSignInRequiredLabel = () => {
    if ((isPrivate || (isSignInAndTermsOfUseRequired && !isVisible && !isAuthenticated)) && !isMinimal) {
      return (
        <section className={styles.cta} onClick={openSignInModal}>
          <span>Sign in</span> to see price
        </section>
      );
    } else if (((isCrea && !isVisible) || (isSignInAndTermsOfUseRequired && !isVisible)) && !isMinimal) {
      return (
        <section className={styles.cta} onClick={openSignInModal}>
          <span>Accept Terms of Use</span> to see price
        </section>
      );
    }
  };

  const hiddenListingPrice = (price: number | null) => {
    const displayPrice = price === 0 ? '$--' : formatCurrency(price || 0);
    return (
      <div className={buildClassName(styles.price, isSoldWithoutSoldPrice && styles['list-price-only'])} data-testid={testId}>
        {isSoldWithoutSoldPrice && 'List: '}{!isVisible ? hiddenPriceLabel : displayPrice}
      </div>
    );
  };

  const displayCountryCurrency = (stateCode: string) =>{
    const lowerCaseCode = stateCode.toLowerCase();
    if (isUsStateCode(lowerCaseCode as ProvinceOrStateCode)) {
      return 'USD';
    } else if (isCanadianProvinceCode(lowerCaseCode as ProvinceOrStateCode)) {
      return 'CAD';
    }
  };

  return isInactive && !isTreb ? null :
    <div className={buildClassName(styles.component, isMinimal && styles.small, isSmallCard && styles['small-card'], isExpThemeStyle && styles.exp)}>
      {listing.province && isVisible &&
        <div className={styles['info-icon-wrapper']}>
          {showsInfoIcon && <InfoIcon className={styles['info-icon']}/>}
          <div className={styles['info-icon-content']}>
            Listed price in listed location currency ({displayCountryCurrency(listing.province)}).
            {isSoldWithoutSoldPrice &&
              <>
                <br/><br/>You are viewing the original listed price (sold price unavailable).
              </>
            }
          </div>
        </div>
      }
      {!!soldPrice && (
        <div className={styles['sold-wrapper']}>
          {hiddenListingPrice(soldPrice)}
          {!(isMinimal && !isVisible) && price &&
            <div className={styles['list-price']}>
              <span data-testid={listingPageModalIds.soldListingListedPrice} className={!isVisible ? 'blur' : ''}>{TryScrambleText(formatCurrency(price), shouldScramble)}</span>
            </div>
          }
        </div>
      )}
      {!soldPrice && price !== null && (
        <div>
          {hiddenListingPrice(price)}
          {renderSignInRequiredLabel()}
        </div>
      )}
    </div>;
}
